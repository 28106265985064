import { useRef, useState } from "react";
import { supabase } from "../lib/api";

const Auth = () => {
    const [helperText, setHelperText] = useState({ error: null, text: null });
    const emailRef = useRef();
   // const passwordRef = useRef();

 /*   const handleLogin = async (type) => {
        const email = emailRef.current?.value;
        const password = passwordRef.current?.value;

        const { user, error } =
            type === "LOGIN"
                ? await supabase.auth.signIn({ email, password })
                : await supabase.auth.signUp({ email, password });

        if (error) {
            setHelperText({ error: true, text: error.message });
        } else if (!user && !error) {
            setHelperText({
                error: false,
                text: "An email has been sent to you for verification!",
            });
        }
    };*/
/*
    const handleOAuthLogin = async (provider) => {
        // You need to enable the third party auth you want in Authentication > Settings
        // Read more on: https://supabase.com/docs/guides/auth#third-party-logins
        let { error } = await supabase.auth.signIn({ provider });
        if (error) console.log("Error: ", error.message);
    };*/
    
    const forgotPassword = async (e) => {
        // Read more on https://supabase.com/docs/reference/javascript/reset-password-email#notes
        e.preventDefault();
       // const email = prompt("Please enter your email:");
        const email = emailRef.current?.value;
        if (email === null || email === "") {
            setHelperText({ error: true, text: "You must enter your email." });
        } else {
            let { error } = await supabase.auth.api.resetPasswordForEmail(
                email
            );
            if (error) {
                console.error("Error: ", error.message);
                setHelperText({
                    error: true,
                    text: error.message,
                });


            } else {
                setHelperText({
                    error: false,
                    text: "Password recovery email has been sent.",
                });
            }
        }
    };

    return (
        <div
            className={
                "w-full h-full sm:h-auto sm:w-2/5 max-w-sm p-5 bg-white shadow flex flex-col text-base"
            }
        >
            <span
                className={
                    "font-sans text-4xl text-center pb-2 mb-1 border-b mx-4 align-center"
                }
            >
                <img src="images/logo_black.svg" alt="wavvit_logo"></img><br></br>
                
                <span className={"aria-18 font-mono   text-green-400"} >Password Reset Panel</span> 
            </span>
            <label
                className={"mt-3 mb-2 font-medium text-lg"}
                htmlFor={"email"}
            >
                <span className={"font-mono mr-1 text-red-400"}>*</span>Email:
            </label>
            
            <input
                className={"bg-gray-100 border py-1 px-3"}
                type={"email"}
                name={"email"}
                ref={emailRef}
                required
            />
           <br></br>
{/* 
            <label
                className={"mt-3 mb-2 font-medium text-lg"}
                htmlFor={"password"}
            >
                <span className={"font-mono mr-1 text-red-400"}>*</span>
                Password:
            </label>
            <input
                className={"bg-gray-100 border py-1 px-3"}
                type={"password"}
                name={"password"}
                ref={passwordRef}
                required
            />
*/} 

    <div className="mt-2 flex">
             
  <span className="block w-full mx-1.5 rounded-md shadow-sm">
                    <button
                        onClick={forgotPassword}
                        type="button"
                        className="flex w-full justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out"
                    >
                       Send Recovery Password via Email
                    </button>
                </span>
                </div> 
                <br></br>

{/*
            <span
                className={
                    "text-blue-600 mt-2 cursor-pointer text-center   text-sm font-medium"
                }
                onClick={forgotPassword}
            >
                Forgot Password?
            </span>
                 */} 
            {!!helperText.text && (
                <div
                    className={`border px-1 py-2 my-2 text-center text-sm ${
                        helperText.error
                            ? "bg-red-100 border-red-300 text-red-400"
                            : "bg-green-100 border-green-300 text-green-500"
                    }`}
                >
                    {helperText.text}
                </div>
            )}
         
        
        </div>
    );
 
};

export default Auth;
